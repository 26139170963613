import React from "react";

const Resources = () => {
  return (
    <div>
      <h4> Welcome to Breezy Edu! </h4>
      <h5>
        Access a wide range of resources for parents and educators to make
        learning at home easy and effective.
      </h5>
      <img className="temp-img" src="resources.jpg" alt="main-img" />
    </div>
  );
};

export default Resources;
