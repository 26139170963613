import React from "react";
import { Link } from "react-router-dom";
import "./Navbar.css";

const Nav = () => {
  return (
    <div>
      <nav className="navbar">
        <div className="logo-section">
          <img src="/logo192.png" alt="Breezy Edu Logo" className="logo" />
          <h3>Breezy Edu</h3>
        </div>
        <div className="nav-links">
          {/* Add your navigation links here */}
          <Link to="">Home</Link>
          <Link to="/activities">Activities</Link>
          <Link to="/resources">Resources</Link>
          <Link to="/donation">Donation</Link>
          <Link to="/about">About</Link>
        </div>
      </nav>
    </div>
  );
};

export default Nav;
