import React from "react";

const About = () => {
  return (
    <div>
      <h4> Welcome to Breezy Edu! </h4>
      <h5>
        Learn more about our mission to support busy parents with essential
        educational resources
      </h5>
      <img className="temp-img" src="about.jpg" alt="main-img" />
    </div>
  );
};

export default About;
