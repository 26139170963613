import React from "react";

const Donation = () => {
  return (
    <div>
      <h4> Welcome to Breezy Edu! </h4>
      <h5>
        Help us keep these resources accessible by supporting our mission with a
        donation.
      </h5>
      <img className="temp-img" src="donation.jpg" alt="main-img" />
    </div>
  );
};

export default Donation;
