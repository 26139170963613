import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./Home/Home";
import Activities from "./Activities/Activities";
import Resources from "./Resources/Resources";
import Donation from "./Donation/Donation";
import About from "./About/About";

import "./Main.css";

const Main = () => {
  return (
    <div className="main">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="Activities" element={<Activities />} />
        <Route path="Resources" element={<Resources />} />
        <Route path="Donation" element={<Donation />} />
        <Route path="About" element={<About />} />
      </Routes>
    </div>
  );
};

export default Main;
