import React from "react";

const Home = () => {
  return (
    <div>
      <h4> Welcome to Breezy Edu! </h4>
      <h5>
        Start here to explore all that we offer for early childhood education
      </h5>

      <img className="temp-img" src="home.jpg" alt="main-img" />
    </div>
  );
};

export default Home;
