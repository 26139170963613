import React from "react";

const Activities = () => {
  return (
    <div>
      <h4> Welcome to Breezy Edu! </h4>
      <h5>
        Discover engaging activities designed to support children’s learning and
        development.
      </h5>
      <img className="temp-img" src="activities.jpg" alt="main-img" />
    </div>
  );
};

export default Activities;
